/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../umart_admin/layout/core';
import { Button, Col, Row } from 'react-bootstrap';
import { DashboardString, String } from '../../../utils/string';
import WareHouseImg from '../../../umart_admin/assets/media/store.png';
import ProductImg from '../../../umart_admin/assets/media/box.png';
import CustomersImg from '../../../umart_admin/assets/media/people.png';
import OrdersImg from '../../../umart_admin/assets/media/Icon.png';
import ActiveCustomersChart from './ActiveCustomersChart';
import OrderReport from './OrderReport';
import APICallService from '../../../api/apiCallService';
import { dashBoardEndPoints } from '../../../api/apiEndPoints';
import SalesReport from './SalesReport';
import Loader from '../../../Global/loader';
import { useSocket } from '../../modules/socket/core/Socket';
import DashboardReport from '../../modals/reports/dashboard-reports';
import MobileDownload from '../../../umart_admin/assets/media/dashboard/people2.svg';
import Method from '../../../utils/methods';
const DashboardPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const [initData, setInitData] = useState<any>();
  const [showReportModal, setShowReportModal] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchInitData();
      setLoading(false);
    })();
  }, []);
  const fetchInitData = async () => {
    setLoading(true);
    const params = {
      needStats: true,
      needCategories: true,
      needTopCustomers: true,
      needTopProducts: true,
    };
    const apiService = new APICallService(
      dashBoardEndPoints.getInitData,
      params
    );
    const response = await apiService.callAPI();
    if (response) {
      const tempResponse = { ...response };
      tempResponse.topCustomers = response?.topCustomers.slice(0, 5);
      tempResponse.topProducts = response?.topProducts.records.slice(0, 5);
      setInitData(tempResponse);
    }
    setLoading(false);
  };
  return (
    <>
      {showReportModal ? (
        <DashboardReport
          show={showReportModal}
          onHide={() => setShowReportModal(false)}
        />
      ) : (
        <></>
      )}
      <Row className="align-items-center">
        <Col
          xs
          className="align-self-center mb-5"
        >
          <h1 className="fs-22 fw-bolder mb-0">
            {DashboardString.dashBoardTitle}
          </h1>
        </Col>
        {!loading && (
          <Col
            xs="auto"
            className="align-self-center mb-5"
          >
            <Button
              variant="primary"
              className="btn-lg"
              onClick={() => setShowReportModal(true)}
            >
              <div className="indicator-label fs-16 fw-bold">
                {'Download report'}
              </div>
            </Button>
          </Col>
        )}
        {loading ? (
          <div className="w-100 d-flex justify-content-center">
            <Loader loading={loading} />
          </div>
        ) : (
          <>
            {' '}
            <Col
              xs={12}
              className="mb-7"
            >
              <Row className="g-10">
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-c9f3d7 rounded py-4 px-5 d-flex justify-content-between align-items-center h-90px p-20">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {initData?.stats?.totalWarehouses || 0}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">
                        {DashboardString.totalWareHouses}
                      </div>
                    </div>
                    <div className="bg-white  rounded-circle w-50px h-50px d-flex align-items-center justify-content-center">
                      <img src={WareHouseImg} />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-fff4d9 rounded py-4 px-5 d-flex justify-content-between align-items-center h-90px">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {initData?.stats?.totalBuyers || 0}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">
                        {DashboardString.totalCustomers}
                      </div>
                    </div>
                    <div className="bg-white  rounded-circle w-50px h-50px d-flex align-items-center justify-content-center">
                      <img src={CustomersImg} />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-c6e4fb rounded py-4 px-5 d-flex justify-content-between align-items-center h-90px">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {initData?.stats?.totalListedProducts || 0}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">
                        {DashboardString.totalProduct}
                      </div>
                    </div>
                    <div className="bg-white  rounded-circle w-50px h-50px d-flex align-items-center justify-content-center">
                      <img src={ProductImg} />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-d5d5f2 rounded py-4 px-5 d-flex justify-content-between align-items-center h-90px">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {initData?.stats?.totalOrders || 0}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">
                        {DashboardString.totalOrders}
                      </div>
                    </div>
                    <div className="bg-white  rounded-circle w-50px h-50px d-flex align-items-center justify-content-center">
                      <img src={OrdersImg} />
                    </div>
                  </div>
                </Col>
                <Col
                  xl={3}
                  md={6}
                  sm={4}
                >
                  <div className="border-r8px bg-dcccda rounded py-4 px-5 d-flex justify-content-between align-items-center h-90px p-20 flex-wrap">
                    <div className="">
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {Method.convertToMillionOrThousand(
                            initData?.stats?.totalAppInstalled &&
                              initData?.stats?.totalAppInstalled[0]
                              ? initData?.stats?.totalAppInstalled[0]
                                  ?.installedDevices || 0
                              : 0
                          )}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">{'Android'}</div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="fs-22 fw-bolder">
                          {Method.convertToMillionOrThousand(
                            initData?.stats?.totalAppInstalled &&
                              initData?.stats?.totalAppInstalled[1]
                              ? initData?.stats?.totalAppInstalled[1]
                                  ?.installedDevices || 0
                              : 0
                          )}
                        </div>
                      </div>
                      <div className="fw-500 fs-16">{'iOS'}</div>
                    </div>
                    <div className="bg-white  rounded-circle w-50px h-50px d-flex align-items-center justify-content-center">
                      <img src={MobileDownload} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              className="mb-7"
            >
              <SalesReport />
            </Col>
            <Col
              xs={12}
              className="mb-2"
            >
              <ActiveCustomersChart />
            </Col>
            <Col
              xs={12}
              className="mb-7"
            >
              <OrderReport
                customers={initData?.topCustomers || []}
                products={initData?.topProducts || []}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};
export { DashboardWrapper };
