export const listCategories = {
  page: 'listCategoriesPage',
  limit: 'listCategoriesLimit',
  scrollPosition: 'listCategoriesScrollPosition',
};
export const listBrands = {
  page: 'listBrandsPage',
  limit: 'listBrandsLimit',
  scrollPosition: 'listBrandsScrollPosition',
};
export const listDeliveryUsers = {
  page: 'listDeliveryUsersPage',
  limit: 'listDeliveryUserLimit',
  deliveriesPage: 'deliveriesTabPage',
  deliveriesLimit: 'deliveriesTabLimit',
  tab: 'deliveryProfileTab',
  scrollPosition: 'listDeliveryUsersScrollPosition',
};
export const listCustomers = {
  page: 'listCustomersPage',
  limit: 'listCustomersLimit',
  search: 'listCustomersSearch',
  ordersFilter: 'listCustomersOrderFilter',
  dateFilter: 'listCustomerRegistrationDate',
  scrollPosition: 'listCustomersScrollPosition',
};
export const listProducts = {
  page: 'listProductsPage',
  limit: 'listProductsLimit',
  statusFilter: 'listProductStatusFilter',
  categoryFilter: 'listProductCategoryFilter',
  searchFilter: 'listProductSearchFilter',
  productScroll: 'listProductScrollPosition',
};
export const listInventory = {
  page: 'listInventoryPage',
  limit: 'listInventoryLimit',
  categoryFilter: 'listInventoryCategoryFilter',
  statusFilter: 'listInventoryStatusFilter',
  expiryFilter: 'listInventoryExpiryFilter',
  stockDayFilter: 'listInventoryStockDayFilter',
  searchFilter: 'listInventorySearchFilter',
  inventoryScroll: 'listInventoryScrollPosition',
};
export const listLowStock = {
  page: 'listLowStockPage',
  limit: 'listLowStockLimit',
  search: 'listLowStockSearch',
  dateFilter: 'listLowStockDateFilter',
  stockRequireFilter: 'listLowStockRequireFilter',
  scrollPosition: 'listLowStockScrollPosition',
};
export const listContactEnquiries = {
  page: 'listContactEnquiriesPage',
  limit: 'listContactEnquiriesLimit',
  search: 'listContactEnquiriesSearch',
  scrollPosition: 'listContactEnquiriesScrollPosition',
};
export const listPromotionCampaign = {
  page: 'listPromotionCampaignPage',
  limit: 'listPromotionCampaignLimit',
  search: 'listPromotionCampaignSearch',
  promotionFilter: 'listPromotionCampaignPromotionFilter',
  statusFilter: 'listPromotionStatusFilter',
  dateFilter: 'listPromotionDateFilter',
  rewardDetailsTab: 'rewardDetailsTab',
  rewardTabSearch: 'rewardTabSearch',
  rewardTabDateFilter: 'rewardTabDateFilter',
  rewardTabLimit: 'rewardTabLimit',
  rewardTabPage: 'rewardTabPage',
  couponDetailsTab: 'couponDetailsTab',
  couponTabLimit: 'couponTabLimit',
  couponTabPage: 'couponTabPage',
  couponTabFilter: 'couponTabFilter',
  scrollPosition: 'listPromotionCampaignScrollPosition',
};
export const listBanner = {
  page: 'listBannerPage',
  limit: 'listBannerLimit',
  statusFilter: 'listBannerStatusFilter',
  placementFilter: 'listBannerPlacementFilter',
  scrollPosition: 'listBannerScrollPosition',
};
export const listGoodsRequest = {
  page: 'listGoodsRequestPage',
  limit: 'listGoodsRequestLimit',
  tab: 'listGoodsRequestTab',
  search: 'listGoodsRequestSearch',
  dateFilter: 'listGoodsRequestDateFilter',
  scrollPosition: 'listGoodsRequestScrollPosition',
};
export const listReturnRequest = {
  page: 'listReturnRequestPage',
  limit: 'listReturnRequestLimit',
  tab: 'listReturnRequestTab',
  search: 'listReturnRequestSearch',
  dateFilter: 'listReturnRequestDateFilter',
  scrollPosition: 'listReturnRequestScrollPosition',
};
export const listOrderDelivery = {
  page: 'listOrderDeliveryPage',
  limit: 'listOrderDeliveryLimit',
  tab: 'listOrderDeliveryTab',
  dateFilter: 'listOrderDeliveryTabDateFilter',
  search: 'listOrderDeliveryTabSearch',
  deliveryUserFilter: 'listOrderDeliveryUserFilter',
  orderDeliveryScrollPosition: 'orderDeliveryScrollPosition',
  orderTypeFilter: 'orderDeliveryOrderTypeFilter',
};
export const productDetailsStore = {
  tab: 'productDetailsTab',
  ratingPage: 'productDetailsTabRatingPage',
  ratingLimit: 'productDetailsTabRatingLimit',
  historyPage: 'productDetailsTabHistoryPage',
  historyLimit: 'productDetailsTabHistoryLimit',
  historyDateFilter: 'productDetailsTabHistoryDateFilter',
  historyOperationFilter: 'productDetailsTabOperationFilter',
};
export const customerDetails = {
  detailsTab: 'customerDetailsTab',
  // addressTab : 'customersDetailsAddressTab',
  // ordersTab : 'customersDetailsOrdersTab',
  // refundsTab : 'customersDetailsRefundsTab',
  // walletTab : 'customerDetailsWalletTab'
};
export const customerProfileOrderTab = {
  page: 'customerProfileOrderTabPage',
  limit: 'customerProfileOrderTabLimit',
  search: 'customerProfileOrderTabSearch',
  orderStatusFilter: 'customerProfileOrderTabStatusFilter',
  dateFilter: 'customerProfileOrderTabDateFilter',
};
export const customerProfileRefundTab = {
  page: 'customerProfileRefundTabPage',
  limit: 'customerProfileRefundTabLimit',
  ordersFilter: 'customerProfileRefundTabOrderFilter',
};
export const customerProfileWalletTab = {
  page: 'customerProfileWalletTabPage',
  limit: 'customerProfileWalletTabLimit',
  dateFilter: 'customerProfileWalletTabDateFilter',
};
export const productZoneList = {
  page: 'productZoneListPage',
  limit: 'productZoneListLimit',
  scrollPosition: 'productZoneListScrollPosition',
};
export const salesReportStore = {
  dateFilter: 'salesReportListDateFilter',
};
export const dashboardStore = {
  salesReportFilter: 'dashboardSalesReportDateFilter',
  activeCustomerFilter: 'dashboardActiveCustomerDateFilter',
  ordersReportFilter: 'dashboardOrdersReportDateFilter',
};
export const recipeListStoreString = {
  page: 'recipeListStoreString',
  limit: 'recipeListStoreStringLimit',
  scrollPosition: 'recipeListStoreStringScrollPosition',
  search: 'recipeListStoreStringSearch',
  categoryFilter: 'recipeListStoreStringCategoryFilter',
  recipeTypeFilter: 'recipeListStoreStringRecipeTypeFilter',
};
export const suggestedProductListStoreString = {
  page: 'suggestedProductListPage',
  limit: 'suggestedProductsListStoreStringLimit',
  scrollPosition: 'suggestedProductsListStoreStringScrollPosition',
  search: 'suggestedProductsListStoreStringSearch',
};
