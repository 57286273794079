export const Auth = {
  login: 'Yay! You are Logged in successfully',
  optSent: 'Hurray! OTP sent to your registered email successfully!',
};
export const masterToast = {
  addBrand: 'Yay! Brand details added successfully!',
  editBrand: 'Success! Brand details updated successfully!',
  deleteBrand: 'Done! Brand details deleted successfully!',
  addPrimaryCategory: 'Great job! Primary category added successfully!',
  editPrimaryCategory: 'Awesome! Primary category updated successfully!',
  addSubCategory: 'Fantastic! Sub category added successfully!',
  deleteCategory: 'Done! Category deleted successfully!',
  editSubCategory: 'Superb! Sub category updated successfully!',
  addGroupCategory: 'Exciting news! Group category added successfully!',
  editGroupCategory: 'Amazing! Group category updated successfully!',
  addVariant: 'Woohoo! Product variant added successfully!',
  editVariant: 'Good news! Product variant updated successfully!',
  deleteSubcategoryVariant:
    'Say goodbye! Sub category variant deleted successfully!',
  deletedVariant: 'Farewell! Variant deleted successfully!',
  addRoles: 'Kudos! Roles & permission added successfully!',
  editRole: 'Bravo! Roles & permission updated successfully!',
  deleteRole: 'Farewell! Roles & permission deleted successfully!',
  addUserManagement: 'Welcome aboard! User added successfully!',
  editUserManagement: 'Success! User updated successfully!',
  addDeliveryUser: 'New member! Delivery user added successfully!',
  editDeliveryUser: 'Updated! Delivery user details updated successfully!',
  deleteDeliveryUser: 'Oh-ho! Delivery user deleted successfully!',
  activatedDeliveryUser: 'Activated! Delivery user activated successfully!',
  deactivatedDeliveryUser:
    'Deactivated! Delivery user deactivated successfully!',
  loadingAreaSaved: 'Success! Loading area details saved successfully.',
  userActivated: 'Cheers! User activated successfully!',
  userDeactivated: 'Quiet time! User deactivated successfully!',
  userDeleted: 'Goodbye! User deleted successfully!',
  userUpdated: 'Update success! User details updated successfully!',
  selectRoles:
    'No permission selected. Please choose at least one to move forward',
  productZoneAdded: 'Success! Product zone added successfully.',
  productZoneUpdated: 'Success! Product zone details saved successfully.',
  productZoneDeleted: 'Done! Product zone deleted successfully!',
};
export const catalogue = {
  added: 'Yay! Product details added successfully!',
  draft: 'Saved! Product details saved as a draft!',
  updated: 'Success! Product details updated successfully!!',
  variantUpdated: 'Updated! Variant details updated successfully',
};
export const inventoryToast = {
  noDateError:
    'Please select expiry date,since previous batches have expiry dates.',
  lowStockListAdded: 'Products added to low stock list successfully!',
  selectForCastedDays: 'Please select stock last for days',
};
export const allProductToast = {
  draftDeleted: 'Farewell! Draft product deleted successfully!',
  oneVariantRequire: 'At lease one variant required',
};
export const ordersToast = {
  dispatch: 'Shipped! Order dispatched successfully!',
  packed: 'Done! Product packed successfully!',
  userAssign: 'Assigned! Delivery user assigned successfully!',
  unpacked: 'Unwrapped! Product unpacked successfully!',
  orderCancelled: 'Order cancelled successfully!',
};
export const customerToast = {
  activated: 'Activated! Customer activated successfully!',
  deactivated: 'Uh-oh! Customer Deactivated successfully!',
  reviewDeleted: 'Review deleted successfully!',
  reviewHides: 'Review hidden successfully!',
  reviewShows: 'Review is displayed successfully!',
};
export const cmsToast = {
  savedAboutUs: 'Saved! About us data saved successfully!',
  savedPolicy: 'Privacy saved! Policy saved successfully!',
  savedTermAndCond: 'Terms locked! Terms and conditions saved successfully!',
  savedRefund: 'Saved! Refund data saved successfully!',
  faqAdded: 'Done! FAQ added successfully! ',
  faqDeleted: 'Oh-Ho! FAQ deleted successfully',
  faqUpdated: 'Updated! FAQ updated successfully!',
};
export const goodsRequestTaost = {
  requestAdded: 'Good request added successfully!',
  requestUpdated: 'Goods request updated successfully!',
  requestCancel: 'Goods request cancelled successfully!',
};
export const customNotificationsToast = {
  selectProductOrCustomer: 'Please select a product or  customer',
  selectCustomer: 'Please select a customer',
  notificationSent: 'Notification sent successfully!',
};
export const bannerToast = {
  bannerAdded: 'Banner added successfully!',
  bannerUpdated: 'Banner updated successfully!',
  bannerDeleted: 'Banner deleted successfully!',
  bannerActivated: 'Banner activated successfully!',
  bannerDeactivated: 'Banner deactivated successfully!',
  validEndDate: 'Please select valid end date',
};
export const lowStockListToast = {
  productOrdered: 'Product ordered successfully!',
};
export const appSettingsToast = {
  appSettingsUpdated: 'App settings updated successfully!',
};
export const promotionCampaignToast = {
  discountPromotionAdded: 'Yay! Discount promotion details added successfully!',
  discountPromotionUpdated:
    'Yay! Discount promotion details updated successfully!',
  campaignActivated: 'Yay! Promotion campaign activated successfully!',
  campaignDeactivated: 'Uh-oh! Promotion campaign deactivated successfully!',
  rewardAdded: 'Yay! Reward details added successfully!',
  rewardUpdated: 'Yay! Reward details updated successfully!',
  invalidEndDate:
    'Invalid date: The end date must be greater than or equal to the start date.',
  couponPromotionAdded: 'Yay! Coupon promotion details added successfully!',
  couponUpdated: 'Yay! Reward details updated successfully!',
  invalidDiscountValue:
    'Invalid Discount Value: Discount value must be less than to minimum purchase amount.',
  promotionDeleted: 'Promotion campaign deleted successfully!',
  discountDeleted: 'Discount promotion campaign deleted successfully!',
  rewardDeleted: 'Reward promotion campaign deleted successfully!',
  couponDeleted: 'Coupon promotion campaign deleted successfully!',
};
export const placeOrderToast = {
  addressAdded: 'Yay! Address details added successfully!',
  selectCustomer: 'Please select a customer',
  selectProduct: 'Please select a product',
  selectAddress: 'Please select a delivery address',
  orderPlaced: 'Yay! Order placed successfully!',
  orderDelivered: 'Yay! Order delivered successfully!',
  customerAdded: 'Yay! Customer added successfully!',
  customerUpdated: 'Yay! Customer details updated successfully!',
};
export const recipeToast = {
  recipeAdded: 'Yay! Recipe details added successfully!',
  recipeUpdated: 'Yay! Recipe details updated successfully!',
  recipeDeleted: 'Oh-Ho! Recipe deleted successfully',
};
